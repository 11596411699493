import theme from './theme';
import logo from './assets/MainLogo.svg';
import squareLogo from './assets/squareLogo.svg';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../../hooks/useSettings/WebsiteSettings';
import { WalnutPartnersFooter } from '../Footer';

// Walnut (commercial) Config
export const WalnutNeoSettings = new WebsiteSettings({
  slug: 'walnutneo',
  host: 'neopartners.commercial.gowalnut.com',
  companyName: 'Walnut',
  // hours: '8:30am - 5:30pm',
  phone: '1-888-966-5308',
  email: 'contact@gowalnut.com',
  // chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo: logo,
  squareLogo: squareLogo,
  title: 'Get your free home insurance quote',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: true,
  contactInformationFirst: true,
  headerCss: { backgroundColor: 'black' },
  Footer: WalnutPartnersFooter,
  // personalInsurance: true,
});
